import restaurant from "./images/cascada.jpg";

import img_card from "./images/familia.jpg";
import imgheadermb from "./images/h13.jpg";
import imgheader from "./images/h13.jpg";
import imgheadermiini from "./images/h14.jpg";
import imgheadermiinimb from "./images/h14.jpg";
import logo from './images/fluture2.png'



const data = {
    introData: [{
        copilul: "Mia",
        familia: "fam. Lăpușneanu",
        logo: logo,
        tata: "Ion",
        mama: "Alina",
        data: "16 Iulie 2022",
        data_confirmare: "5 iulie 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "ghiletchi.alina@gmail.com", 
       tel: "+373 682 29 079",
       phone: "tel:+37368229079",
       viber: "viber://chat?number=%2B37368229079",
       whatsapp: "https://wa.me/+37369370867",
       messenger: "https://www.messenger.com/t/alina.ghiletchi.5",
    }],
    cardData: [
   
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Cascada",
            data: "16 iulie 2022, sâmbătă, ora 17:00",
            adress: "str. T. Bubuiog 1C, Bubuieci, Moldova",
            harta: "https://goo.gl/maps/xZocAYZsBUeSCj5p6",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10886.81211565706!2d28.9387682!3d46.9871673!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x27ddf0166ec31992!2sCascada!5e0!3m2!1sro!2s!4v1653459760436!5m2!1sro!2s" 
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Copii sunt un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;